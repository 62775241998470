import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/container';
import PageTitle from '../components/page-title';
import ExpandableSection from '../components/expandable-section';

type PressCenterData = {
  allPressCenterYaml: {
    edges: {
      node: {
        sectionName: string,
        content?: string,
        items?: {
          title: string,
          content?: string,
          years?: {
            id: string,
            content?: string,
            periods: {
              name: string,
              content: string,
            } [],
          } [],
        } [],
      }
    } [],
  }
}

const query = graphql`
  query {
    allPressCenterYaml {
      edges {
        node {
          sectionName
          content
          items {
            title
            content
            years {
              id
              content
              periods {
                name
                content
              }
            }
          }
        }
      }
    }
  }
`;

const PressCenter: React.FC = () => {
  const data = useStaticQuery<PressCenterData>(query);
  const pressCenterData = data.allPressCenterYaml.edges.map(({node}) => node);

  return (
    <Layout>
      <SEO title="Прес-центр" />
      <Container className="general">
        <PageTitle title="Прес-центр" />

        {pressCenterData.map(section => (
          <ExpandableSection key={section.sectionName} summary={section.sectionName} innerHTML={section.content}>
            {section.items && section.items.map(item => (
              <ExpandableSection key={item.title} summary={item.title} innerHTML={item.content}>
                {item.years && item.years.map(year => (
                  <ExpandableSection key={year.id} summary={year.id}>
                    {year.content && <div dangerouslySetInnerHTML={{__html: year.content}}></div>}
                    {year.periods.map(period => (
                      <ExpandableSection key={period.name} summary={period.name} innerHTML={period.content} />
                    ))}
                  </ExpandableSection>
                ))}
              </ExpandableSection>
            ))}
          </ExpandableSection>
        ))}
        </Container>
    </Layout>
  )
}

export default PressCenter;